import { createGlobalStyle } from "styled-components";
import "@fontsource/raleway";
import { myColors, FontSizes } from "./constans";

export const GlobalStyle = createGlobalStyle`
body {
    padding: 0;
    margin: 0;
    color: black;
    font-family: 'raleway';
    font-weight: bold;
    max-width: 100vw;
}

*, *::hover, *::after{
    box-sizing: inherit;
    font-display: swap;
    font-family: "Montserrat !important";
  
}

div, button {
  -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
      
    &:focus {
      outline: none !important;
    }
}

h1, h2, h3, h4, h5, p, button {
    font-family: 'raleway';
    color: ${myColors.myBlack.hash};
   
}

p {
  margin: 10px 0;
 
}

a {
    text-decoration: none !important;
}
h1 {
  letter-spacing: 1.2px;
    font-size: ${FontSizes.max359.h1};
    font-family: 'raleway';
    @media (min-width: 359px) {
      font-size: ${FontSizes.min359.h1};
    }
    @media (min-width: 413px) {
      font-size: ${FontSizes.min413.h1};
    }
    @media (min-width: 1023px) {
      font-size: ${FontSizes.min1023.h1};
    }
    @media (min-width: 1200px) {
      font-size: ${FontSizes.min1200.h1};
    }
}

h2 {
  letter-spacing: 1.2px;
    font-size: ${FontSizes.max359.h2};
    font-family: 'raleway';
    @media (min-width: 359px) {
      font-size: ${FontSizes.min359.h2};
    }
    @media (min-width: 413px) {
      font-size: ${FontSizes.min413.h2};
    }
    @media (min-width: 1023px) {
      font-size: ${FontSizes.min1023.h2};
    }
    @media (min-width: 1200px) {
      font-size: ${FontSizes.min1200.h2};
    }
}

h3 {
  letter-spacing: 1.2px;
    font-size: ${FontSizes.max359.h3};
    font-family: 'raleway';
    @media (min-width: 359px) {
      font-size: ${FontSizes.min359.h3};
    }
    @media (min-width: 413px) {
      font-size: ${FontSizes.min413.h3};
    }
    @media (min-width: 1023px) {
      font-size: ${FontSizes.min1023.h3};
    }
    @media (min-width: 1200px) {
      font-size: ${FontSizes.min1200.h3};
    }
}

h4 {
  letter-spacing: 1.2px;
    font-size: ${FontSizes.max359.h4};
    font-family: 'raleway';
    @media (min-width: 359px) {
      font-size: ${FontSizes.min359.h4};
    }
    @media (min-width: 413px) {
      font-size: ${FontSizes.min413.h4};
    }
    @media (min-width: 1023px) {
      font-size: ${FontSizes.min1023.h4};
    }
    @media (min-width: 1200px) {
      font-size: ${FontSizes.min1200.h4};
    }
}


h5 {
  letter-spacing: 1.2px;
    font-size: ${FontSizes.max359.h5};
    font-family: 'raleway';
    @media (min-width: 359px) {
      font-size: ${FontSizes.min359.h5};
    }
    @media (min-width: 413px) {
      font-size: ${FontSizes.min413.h5};
    }
    @media (min-width: 1023px) {
      font-size: ${FontSizes.min1023.h5};
    }
    @media (min-width: 1200px) {
      font-size: ${FontSizes.min1200.h5};
    }
}


p {
    font-size: ${FontSizes.max359.p};
    font-family: 'raleway';
    @media (min-width: 359px) {
      font-size: ${FontSizes.min359.p};
    }
    @media (min-width: 413px) {
      font-size: ${FontSizes.min413.p};
    }
    @media (orientation: landscape) {
      font-size: ${FontSizes.min359.p};
    }
    @media (min-width: 1023x) {
      font-size: ${FontSizes.min1023.p};
      line-height: ${FontSizes.min1023.p} + 10px;
    }
    @media (min-width: 1200px) {
      font-size: ${FontSizes.min1200.p};
    }
}

`;
