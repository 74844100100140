export const zIndexes = {
  background: 0,
  menu: 10,
  appBar: 20,
  modal: 100,
};

export const myColors = {
  myBlack: { r: 54, g: 52, b: 50, hash: "#363432" },
  myGray: { r: 221, g: 221, b: 218, hash: "#dddddd" },
  myLightGreen: { r: 210, g: 227, b: 215, hash: "#d2e3d7" },
  myDarkGreen: { r: 22, g: 117, b: 101, hash: "#167565" },
  myGold: { r: 205, g: 163, b: 31, hash: "#cda31f" },
  logoDropColor: "#007266",
};

export const LineHeight = "1px";

export const ModalHeaderHeight = "90px";

export const ModalElementsSizes = {
  max359: {
    headerHeight: "91px",
  },
  min359: {
    headerHeight: "101px",
  },
  min413: {
    headerHeight: "101px",
  },
  min1023: {
    headerHeight: "101px",
  },
  min1200: {},
};

export const FontSizes = {
  max359: {
    h1: "22px",
    h2: "16px",
    h3: "15px",
    h4: "14px",
    h5: "13px",
    p: "11px",
  },
  min359: {
    h1: "24px",
    h2: "22px",
    h3: "20px",
    h4: "14px",
    h5: "14px",
    p: "14px",
  },
  min413: {
    h1: "28px",
    h2: "22px",
    h3: "20px",
    h4: "16px",
    h5: "15px",
    p: "15px",
  },
  min1023: {
    h1: "28px",
    h2: "24px",
    h3: "22px",
    h4: "20px",
    p: "18",
  },
  min1200: {
    h1: "28px",
    h2: "24px",
    h3: "22px",
    h4: "12px",
    p: "20",
  },
};
