import React from "react";
import styled from "styled-components";
import { myColors } from "../style/constans";

const PageLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  background-color: ${myColors.myLightGreen.hash};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PageLoader = () => {
  return (
    <PageLoaderWrapper>
      <h2>Trwa ładowanie ...</h2>
    </PageLoaderWrapper>
  );
};

export default PageLoader;
