import translationPL from "./../src/locales/PL/translation.json";
import translationEN from "./../src/locales/EN/translation.json";
import translationIT from "./../src/locales/IT/translation.json";
import translationDE from "./../src/locales/DE/translation.json";

const resources = {
  pl: {
    translation: translationPL,
  },
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
  de: {
    translation: translationDE,
  },
};

export const initConfiguration = {
  resources,
  lng: "pl",
  fallbackLng: ["pl", "en", "it", "de"],
  debug: true,
  react: {
    wait: true,
    omitBoundRerender: false,
  },
  interpolation: {
    escapeValue: false,
  },
};
