import React, { lazy, Suspense } from "react";
import Helmet from "react-helmet";
import PageLoader from "./../components/PageLoader";
import { graphql } from "gatsby";
import { GlobalStyle } from "./../style/globalStyle";
import i18n from "i18next";
import {
  initReactI18next,
  I18nextProvider,
  useTranslation,
} from "react-i18next";
import { initConfiguration } from "./../i18n";

const Layout = lazy(() => import("./../ui/Layout"));

i18n.use(initReactI18next).init(initConfiguration);

export const query = graphql`
  {
    background: file(name: { eq: "background" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logoV: file(name: { eq: "logoV" }) {
      childImageSharp {
        fluid(quality: 10) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bottleMilkImg: file(name: { eq: "bottleMilkImg" }) {
      childImageSharp {
        fluid(quality: 70) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    cheesesImg: file(name: { eq: "cheesesImg" }) {
      childImageSharp {
        fluid(quality: 10) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    powderMilkImg: file(name: { eq: "powderMilkImg" }) {
      childImageSharp {
        fluid(quality: 10) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    arsidVodo: file(name: { eq: "arsidVodo" }) {
      childImageSharp {
        fluid(quality: 50) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    piotrSmoluch: file(name: { eq: "piotrSmoluch" }) {
      childImageSharp {
        fluid(quality: 50) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jaroslawLis: file(name: { eq: "jaroslawLis" }) {
      childImageSharp {
        fluid(quality: 10) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    gabrielaRenik: file(name: { eq: "gabrielaRenik" }) {
      childImageSharp {
        fluid(quality: 10) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    katarzynaMontesano: file(name: { eq: "katarzynaMontesano" }) {
      childImageSharp {
        fluid(quality: 10) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    teamImg: file(name: { eq: "team" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    Polmlek: file(name: { eq: "Polmlek" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Lumiko: file(name: { eq: "Lumiko" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Muller: file(name: { eq: "Muller" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Lactalis: file(name: { eq: "Lactalis" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    OSMLowicz: file(name: { eq: "OSMLowicz" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    flagPL: file(name: { eq: "flagPL" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flagIT: file(name: { eq: "flagIT" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flagDE: file(name: { eq: "flagDE" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flagEN: file(name: { eq: "flagEN" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    certificateBrc: file(name: { eq: "certificateBrc" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const pageStyles = {
  width: "100%",
  margin: 0,
  maxWidth: "100vw",
  maxHeight: "100vh",
  overflow: "hidden",
};

const IndexPage = (props) => {
  const { t } = useTranslation();
  const isSSR = typeof window === "undefined";

  const offerImages = [
    props.data.bottleMilkImg.childImageSharp.fluid,
    props.data.powderMilkImg.childImageSharp.fluid,
    props.data.cheesesImg.childImageSharp.fluid,
  ];

  const partners = [
    { name: "Lumiko", img: props.data.Lumiko.childImageSharp.fluid },
    { name: "OSMLowicz", img: props.data.OSMLowicz.childImageSharp.fluid },
    { name: "Polmlek", img: props.data.Polmlek.childImageSharp.fluid },
    { name: "Muller", img: props.data.Muller.childImageSharp.fluid },
    { name: "Lactalis", img: props.data.Lactalis.childImageSharp.fluid },
  ];

  const flags = {
    pl: props.data.flagPL.childImageSharp.fluid,
    en: props.data.flagEN.childImageSharp.fluid,
    de: props.data.flagDE.childImageSharp.fluid,
    it: props.data.flagIT.childImageSharp.fluid,
  };

  const team = [
    {
      name: "Arsid Vodo",
      img: props.data.arsidVodo.childImageSharp.fluid,
      tel: "+ 48 660 59 50 50",
      email: "vodo@vavalact.pl",
      role: t("team.marketEu"),
    },
    {
      name: "Piotr Smoluch",
      img: props.data.piotrSmoluch.childImageSharp.fluid,
      tel: "+ 48 784 08 40 40",
      email: "smoluch@vavalact.pl",
      role: t("team.marketPl"),
    },
    {
      name: "Jarosław Lis",
      img: props.data.jaroslawLis.childImageSharp.fluid,
      tel: "+ 48 538 38 60 60",
      email: "lis@vavalact.pl",
      role: t("team.marketPl"),
    },
    {
      name: "Gabriela Gołaszewska",
      img: props.data.gabrielaRenik.childImageSharp.fluid,
      tel: "+ 48 668 79 60 60",
      email: "gabriela@vavalact.pl",
      role: t("team.marketEu"),
    },
    {
      name: "Katarzyna Montesano",
      img: props.data.katarzynaMontesano.childImageSharp.fluid,
      tel: "+ 48 734 48 30 30",
      email: "katarzyna@vavalact.pl",
      role: t("team.bookkepping"),
    },
  ];

  return (
    <I18nextProvider>
      <Helmet
        htmlAttributes={{
          lang: "pl",
        }}
        meta={[
          {
            name: "Vavalact",
            content:
              "Vavalact to przede wszystkim wysoka jakość produktów i usług. Priorytety, którymi się kierujemy, pozwoliły nam podjąć współpracę z czołowymi producentami żywności - zarówno w Polsce, jak i w wielu krajach Unii Europejskiej. ",
          },
        ]}
      >
        <html lang="pl" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Vavalact to przede wszystkim wysoka jakość produktów i usług. Priorytety, którymi się kierujemy, pozwoliły nam podjąć współpracę z czołowymi producentami żywności - zarówno w Polsce, jak i w wielu krajach Unii Europejskiej. "
        />
        <meta
          name="keywords"
          content="mleko, warszawa, vavalact, nabiał, porozmawiajmy o mleku, arsid vodo, sery, hurtownia, dostawca"
        />
        <title>Vavalact</title>
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        />
      </Helmet>
      <main style={pageStyles}>
        <GlobalStyle />
        {!isSSR && (
          <Suspense fallback={<PageLoader />}>
            <Layout
              backgroundImage={props.data.background.childImageSharp.fluid}
              offerImages={offerImages}
              team={team}
              teamImg={props.data.teamImg.childImageSharp.fluid}
              partners={partners}
              flags={flags}
              certificateBrc={props.data.certificateBrc.childImageSharp.fluid}
            />
          </Suspense>
        )}
      </main>
    </I18nextProvider>
  );
};

export default IndexPage;
